<template>
  <main id="PrivacyPolicy">
    <div class="row">
      <article>
        <header>
          <h2>個人資料蒐集使用保護聲明</h2>
        </header>
        <div class="content">
          <p>
            「個人形象網站MYii」是由「泳欣事業有限公司」（下稱我們）所經營之網站(下稱本網站)各項服務與資訊。<br />
            以下是我們的隱私權保護政策，幫助您瞭解本網站所蒐集的個人資料之運用及保護方式。
          </p>
          <ol>
            <li>
              <p>一、隱私權保護政策的適用範圍</p>
              <ol>
                <li>
                  （1）請您在於使用本網站服務前，確認您已審閱並同意本隱私權政策所列全部條款，若您不同意全部或部份者，則請勿使用本網站服務。
                </li>
                <li>
                  （2）隱私權保護政策內容，包括我們如何處理您在使用本網站服務時蒐集到的個人識別資料。
                </li>
                <li>
                  （3）隱私權保護政策不適用於本網站以外的相關連結網站，亦不適用於非我們所委託或參與管理之人員。
                </li>
              </ol>
            </li>

            <li>
              <p>二、個人資料的蒐集及使用</p>
              <ol>
                <li>
                  （1）於一般瀏覽時，伺服器會自行記錄相關行徑，包括您使用連線設備的IP位址、使用時間、使用的瀏覽器、瀏覽及點選資料記錄等，做為我們增進服務的參考依據，此記錄為內部應用，絕不對外公布。
                </li>
                <li>
                  （2）在使用我們的服務時，我們可能會要求您向我們提供可用於聯繫或識別您的某些個人資料，包括：
                  <ol>
                    <li>
                      • C001辨識個人者： 如姓名、地址、電話、電子郵件等資訊。
                    </li>
                    <li>• C002辨識財務者： 如信用卡或金融機構帳戶資訊。</li>
                  </ol>
                </li>
                <li>
                  （3）本網站將蒐集的數據用於各種目的：
                  <ol>
                    <li>• 提供和維護系統所提供讀服務</li>
                    <li>
                      • 提供用戶支持 •提供分析或有價值訊息，以便我們改進服務
                    </li>
                    <li>• 監控服務的使用情況</li>
                    <li>• 檢測，預防和解決技術問題</li>
                  </ol>
                </li>
                <li>
                  （4）本網站針對蒐集數據的利用期間、地區、對象及方式：
                  <ol>
                    <li>
                      • 期間：當事人要求停止使用或本服務停止提供服務之日為止。
                    </li>
                    <li>• 地區：個人資料將用於台灣地區。</li>
                    <li>
                      •
                      利用對象及方式：所蒐集到的資料將利用於各項業務之執行，利用方式為因業務執行所必須進行之各項分析、聯繫及通知。
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </article>
    </div>
    <Footer />
  </main>
</template>

<script>
import SmoothScrollResister from '@/common/GSAP/SmoothScrollResister.js';
import Footer from '@/components/MainFooter';
import { GetMetaData } from '@/common/meta';
export default {
  name: 'PrivacyPolicy',
  mixins: [SmoothScrollResister],
  components: {
    Footer,
  },
  data() {
    return {
      meta_data: null,
    };
  },
  methods: {},
  mounted() {
    this.RegisterGsap('PrivacyPolicy');
          window.prerenderReady = true;
  },
  created() {
    this.meta_data = GetMetaData('隱私權政策', '', '');
  },
  metaInfo() {
    return this.meta_data;
  },
};
</script>
